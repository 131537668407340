<template>
        <footer>
            <p>© Joshua Hamilton-Brown 2024</p>
            <p>Attribution:</p>
        </footer>
</template>

<script>

</script>

<style>
    @import './styles/FooterBar.css'
</style>